"use client";
import { ERROR_TOAST_DURATION_MS } from "@/lib/constants";
import { ApiError } from "@llamaindex/cloud/api";
import { NotFound } from "@llamaindex/component/not-found";
import { toast } from "@llamaindex/component/ui/use-toast";
import { useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { useAppStore } from "@/store";
import { useQueryClient } from "@tanstack/react-query";

export function RootErrorBoundary(props: FallbackProps) {
  const { replace } = useRouter();
  const queryClient = useQueryClient();
  const { error } = props;
  useEffect(() => {
    if (error instanceof ApiError && error.status === 401) {
      replace("/login");
    } else if (error instanceof ApiError) {
      toast.error(
        (error.body as any)?.detail ?? error.message ?? "An error occurred",
        { duration: ERROR_TOAST_DURATION_MS },
      );
    }
  }, [error, replace]);
  return (
    <div role="alert" className="flex size-full items-center justify-center">
      <NotFound
        onClickBackHome={useCallback(() => {
          useAppStore.persist.clearStorage();
          queryClient.clear();
          replace("/");
        }, [queryClient, replace])}
        onClickContact={useCallback(() => {
          // we might cannot use notion here because BYOC deployment does not have notion integration
          window.open("mailto:help@runllama.ai", "_blank");
        }, [])}
      />
    </div>
  );
}
