"use client";
import { captureException } from "@sentry/nextjs";

import { RootErrorBoundary } from "@/components/section/error-boundary/root-error-boundary";
import { useEffect } from "react";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <RootErrorBoundary error={error} resetErrorBoundary={reset} />
      </body>
    </html>
  );
}
