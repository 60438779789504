import { create } from "zustand";
import { combine, persist, subscribeWithSelector } from "zustand/middleware";

type AppState = {
  projectId: string | null;
  isCollapsed: boolean;
  panelSizes: number[];
};

type AppAction = {
  setPanelSizes(sizes: number[]): void;
  setProjectId(projectId: string): void;
  setIsCollapsed(isCollapsed?: boolean): void;
};

export const useAppStore = create(
  subscribeWithSelector(
    persist(
      combine<AppState, AppAction>(
        {
          panelSizes: [33, 67],
          projectId: null,
          isCollapsed: false,
        },
        (set, get, store) => ({
          setPanelSizes(sizes) {
            set({
              panelSizes: sizes,
            });
          },
          setProjectId(projectId: string) {
            set({ projectId });
          },
          setIsCollapsed(isCollapsed?: boolean) {
            set((value) => ({ isCollapsed: isCollapsed ?? value.isCollapsed }));
          },
        }),
      ),
      {
        name: "app-store",
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              ["projectId", "isCollapsed", "panelSizes"].includes(key),
            ),
          ),
      },
    ),
  ),
);
