import { Button } from "../ui/button";

export type NotFoundProps = {
  onClickContact?: () => void;
  onClickBackHome?: () => void;
};

export const NotFound = (props: NotFoundProps) => {
  return (
    <div className="relative inline-flex flex-col items-center justify-center gap-4">
      <div className="text-5xl text-gray-900">404</div>
      <div className="text-[22px] font-bold text-black">Page is not found</div>
      <div className="max-w-[363px] text-center text-base font-normal leading-normal text-gray-900">
        Something went wrong. Please try again later or contact support with the
        error message screenshot
      </div>
      <div className="inline-flex items-start justify-center gap-[7px]">
        <Button
          variant="outline"
          className="text-sm font-semibold text-gray-900"
          onClick={props.onClickContact}
        >
          Contact Support
        </Button>
        <Button
          className="text-sm font-semibold text-white"
          onClick={props.onClickBackHome}
        >
          Go Back Home
        </Button>
      </div>
    </div>
  );
};
